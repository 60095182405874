var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew6-white-sheet",
    {
      staticClass:
        "mew-component--features-send pa-6 pa-md-10 no-pointer-events",
      attrs: { "max-width": "700px" },
    },
    [
      _c("div", { staticClass: "mew-heading-1 mb-3" }, [
        _vm._v(" " + _vm._s(_vm.$t("home.features.eth.title")) + " "),
      ]),
      _c(
        "div",
        { staticClass: "mt-10" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("mew-select", {
                    attrs: { label: "Type", items: _vm.tokens },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("mew-input", {
                    attrs: {
                      placeholder: "0x...",
                      label: _vm.$t("sendTx.amount"),
                    },
                    model: {
                      value: _vm.data,
                      callback: function ($$v) {
                        _vm.data = $$v
                      },
                      expression: "data",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "mt-n5", attrs: { cols: "12" } },
                [_c("module-address-book", { attrs: { "is-home-page": "" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }